import React from "react"

import styled from 'styled-components';

import office from '../../../images/office.jpg'

import theme from "../../../styles/theme";

const CompanyWrapper = styled.div`
    margin-top: 100px;
    ul {
        list-style-type: square;
        margin-bottom: 10%;
        margin-top: 10%;

        font-size: 0.9rem;

        @media(min-width: 992px) {
            margin-top: 4%;
            margin-bottom: 4%;
        }
    }

    display: flex;
    flex-direction: column;

    @media(min-width: 992px) {
        flex-direction: row;
    }

    p {
        font-size: 0.9rem;
    }
`;

const DataWrapper = styled.div`
    @media(min-width: 992px) {
        width: 90%;
    }
`;

const ImageWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media(min-width: 992px) {
        width: 70%;
    }

    h2 {
        font-weight: 400;
        line-heght: 30px;
    }

    img {
        border: 1px solid #EBEBEB;
        padding: 6px;
    }
`;

const CompanyInformation = () => (
    <CompanyWrapper>
        <DataWrapper>
        <p>Firma Infofinder powstała w 2011 roku. Od początku skupia się na tworzeniu rozwiązań potrzebnych do zarządzania ryzykiem w szpitalu, automatyzacją procesów oraz wdrażaniem systemów logistyki szpitalnej. Firma działa w oparciu o polską i europejską technologię inżynierską, informatyczną i logistyczną.
        Zajmujemy się tworzeniem systemów optymalizujących procesy zachodzące w jednostkach służby zdrowia w takich obszarach jak:</p>
        <ul>
            <li>Gospodarka lekowa</li>
            <li>Gospodarka materiałowa</li>
            <li>Gospodarka energetyczna</li>
            <li>Systemy zmniejszające ryzyko powstawania zakażeń wewnątrzszpitalnych</li>
        </ul>
        <p>
        Wspieramy polskich przedstawicieli ochrony zdrowia w zakresie wdrażania standardów zarządzania
        procesami logistyki szpitalnej ułatwiając pracę personelu placówek medycznych oraz zwiększając
        bezpieczeństwo pacjentów.
        </p>
        </DataWrapper>
        <ImageWrapper>
            <h2>BIURO FIRMY<br />INFOFINDER SP. Z O. O.</h2>
            <img src={office} alt="Biuro" />
        </ImageWrapper>
    </CompanyWrapper>
)

export default CompanyInformation

