import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import CompanyInformation from "../components/Company/Sections/CompanyInformation";
import CeoInformation from "../components/Company/Sections/CeoInformation";

const AboutUsPage = () => (
  <Layout>
    <SEO title="O nas" />
    <Hero title="Informacje o firmie" />
    <CompanyInformation />
    <CeoInformation />
  </Layout>
)

export default AboutUsPage
