import React from "react"

import styled from 'styled-components';

import office from '../../../images/mkleszczewski.jpg'

import theme from "../../../styles/theme";

const CeoWrapper = styled.div`
    margin-top: 50px;
    padding-top: 50px;

    border-top: 1px solid ${theme.colors.lightGray};

    ul {
        list-style-type: square;
        margin-bottom: 10%;
        margin-top: 10%;

        font-size: 0.9rem;

        @media(min-width: 992px) {
            margin-top: 4%;
            margin-bottom: 4%;
        }
    }

    display: flex;
    flex-direction: column;

    @media(min-width: 992px)
    {
        flex-direction: row;
    }

    p {
        font-size: 0.9rem;
    }
`;

const DataWrapper = styled.div`
    @media(min-width: 992px) {
        width: 90%;
    }
`;

const ImageWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        font-weight: 400;
        line-heght: 30px;
    }

    img {
        border: 1px solid #EBEBEB;
        padding: 6px;
        max-width: 80%;

        @media(min-width: 1200px) {
            max-width: 40%;
        }
    }

    @media(min-width: 992px) {
        width: 70%;
    }
`;

const CeoInformation = () => (
    <CeoWrapper>
        <ImageWrapper>
            <h2>PREZES ZARZĄDU<br/>FIRMY INFOFINDER</h2>
            <img src={office} alt="Mariusz Kleszczewski"/>
        </ImageWrapper>
        <DataWrapper>
            <p>Mariusz Kleszczewski posiada wieloletnie doświadczenie menedżerskie w pracy na różnych szczeblach organizacji:</p>
            <ul>
                <li>
                    informatycznych
                </li>
                <li>
                    samorządowych
                </li>
                <li>
                    służby zdrowia
                </li>
            </ul>
            <p>W 1990 ukończył wydział lekarski Śląskiej Akademii Medycznej. W 2010 roku ukończył studia podyplomowe w Wyższej Szkole Ekonomii i Informatyki w Krakowie. Praktykujący lekarz i praktyk zarządzania. Od 1990 roku związany z medycyną, od 1996 r. z zarządzaniem służbą zdrowia, od 4 lat prowadzi firmy z branży medycznej IT.</p>   
        </DataWrapper>
       </CeoWrapper>
)

export default CeoInformation

